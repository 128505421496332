<template>
  <c-box w="full">
    <Breadcrumb :items="breadcrumbs" />
    <hr v-chakra mt="20px" />
    <c-text
      mt="30px"
      font-family="Montserrat"
      font-size="24px"
      font-weight="700"
      line-height="36px"
    >
      Tambah Notifikasi
    </c-text>
    <FormCreate v-chakra mt="30px" v-model="notifikasi" />
    <c-stack mt="30px" is-inline spacing="20px">
      <c-button
        rounded="full"
        h="62px"
        w="289px"
        as="router-link"
        :to="{ name: 'superadmin.notifikasi' }"
      >
        Batal
      </c-button>
      <c-button
        variant-color="brand"
        rounded="full"
        h="62px"
        w="289px"
        @click.prevent="submit()"
      >
        Simpan
      </c-button>
    </c-stack>
  </c-box>
</template>

<script>
import FormCreate from "./form-create.vue";
import Breadcrumb from "@/components/breadcrumb";

export default {
  name: "SUNotifikasiCreate",
  components: { Breadcrumb, FormCreate },
  data() {
    return {
      notifikasi: null,
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {
          label: "Manajemen Notifikasi",
          href: this.$router.resolve({ name: "superadmin.notifikasi" }).href,
        },
        { label: "Tambah Notifikasi", isCurrent: true },
      ];
    },
  },
  methods: {
    async submit() {
      this.$toast({
        title: "Membuat notifikasi",
        variant: "left-accent",
        position: "bottom-right",
      });
      let notif = await this.$store.dispatch(
        "suManagementNotifikasi/create",
        this.notifikasi
      );
      this.$toast({
        title: "Notifikasi berhasil dibuat",
        status: "success",
        variant: "left-accent",
        position: "bottom-right",
      });
      await this.$router.replace({
        name: "superadmin.notifikasi-detail",
        params: { notifikasiId: notif.id },
      });
    },
  },
};
</script>

<style scoped></style>
