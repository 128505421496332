<template>
  <c-box w="full">
    <c-flex mt="20px">
      <c-form-control is-required :is-invalid="$v.name.$invalid" w="590px">
        <c-form-label>Nama Notifikasi</c-form-label>
        <c-input h="62px" type="text" v-model="name" />
      </c-form-control>
    </c-flex>
    <c-flex mt="20px">
      <c-form-control is-required :is-invalid="$v.type.$invalid" w="590px">
        <c-form-label>Tipe Notifikasi</c-form-label>
        <c-select h="62px" v-model="type" placeholder="Tipe Notifikasi">
          <option value="direct">Direct</option>
          <option value="scheduler">Schedule</option>
        </c-select>
      </c-form-control>
    </c-flex>
    <c-flex v-if="type === 'scheduler'" mt="20px">
      <c-form-control
        :is-required="type === 'scheduler'"
        :is-invalid="$v.dateTime.$invalid"
        w="590px"
      >
        <c-form-label>Tanggal &amp; Waktu Notifikasi</c-form-label>
        <c-input-group>
          <c-input
            h="62px"
            border-width="1px"
            type="text"
            v-model="datetimeShow"
            v-chakra="{
              pointerEvents: 'none',
              '&[data-invalid]': {
                boxShadow: '0 0 0 1px #e66673 !important',
              },
            }"
          />
          <c-input-right-element v-chakra h="62px" w="62px">
            <Popper trigger="clickToOpen">
              <c-box>
                <v-date-picker
                  mode="dateTime"
                  :timezone="''"
                  v-model="dateTime"
                />
              </c-box>
              <c-button
                slot="reference"
                variant="solid"
                rounded-left="0"
                rounded-right="md"
                h="60px"
                w="60px"
                size="md"
                m="0"
                p="0"
                min-w="0"
              >
                <c-image
                  :src="require('@/assets/icon-clock.svg')"
                  alt="show date icon"
                  size="24px"
                />
              </c-button>
            </Popper>
          </c-input-right-element>
        </c-input-group>
      </c-form-control>
    </c-flex>
    <c-flex mt="20px">
      <c-form-control is-required :is-invalid="$v.content.$invalid" w="590px">
        <c-form-label>Isi Notifikasi</c-form-label>
        <c-input h="62px" type="text" v-model="content" />
      </c-form-control>
    </c-flex>
    <c-flex mt="20px">
      <c-form-control is-required :is-invalid="$v.target.$invalid" w="590px">
        <c-form-label>Tujuan Notifikasi</c-form-label>
        <c-checkbox-group spacing="10px" variant-color="brand" v-model="target">
          <c-checkbox value="client">Klien</c-checkbox>
          <c-checkbox value="nutritionist">Ahli Gizi</c-checkbox>
        </c-checkbox-group>
      </c-form-control>
    </c-flex>
  </c-box>
</template>

<script>
import Popper from "vue-popperjs";
import "vue-popperjs/dist/vue-popper.min.css";
import { required, requiredIf } from "vuelidate/lib/validators";
import dayjs from "dayjs";
import equal from "fast-deep-equal";

export default {
  name: "SUNotifikasiCreateForm",
  components: { Popper },
  props: ["value"],
  data() {
    return {
      name: null,
      type: null,
      content: null,
      target: null,
      dateTime: null,
    };
  },
  validations: {
    name: { required },
    type: { required },
    content: { required },
    target: { required },
    dateTime: { required: requiredIf((it) => it.type === "scheduler") },
  },
  computed: {
    datetimeShow() {
      if (this.dateTime == null) return "";
      return dayjs(this.dateTime).format("DD/MM/YYYY hh:mm");
    },
    submittedValue() {
      return {
        name: this.name,
        type: this.type,
        content: this.content,
        target: this.target,
        dateTime: this.dateTime,
      };
    },
  },
  watch: {
    submittedValue(value, old) {
      if (equal(value, old)) return;

      this.$emit("input", value);
    },
    value: {
      immediate: true,
      handler(val, old) {
        if (val == null) return;
        if (equal(val, old)) return;

        this.name = val.name;
        this.type = val.type;
        this.content = val.content;
        this.target = val.target;
        this.dateTime = val.dateTime;
      },
    },
  },
};
</script>
